<template>
    <div>
        <h1 class="text-center text-4xl font-semibold text-white mb-2 mt-4">Upload Report</h1>
        <b-row>
            <b-col lg="3" md="4" sm="12">
                <b-form-group label="Title Report">
                    <b-form-input v-model="title" placeholder="Title Report" required />
                </b-form-group>
            </b-col>
        </b-row>
        <div class="flex items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg 
                cursor-pointer bg-gray-50/10 hover:bg-gray-50/30">
                <div class="flex flex-col items-center justify-center pt-5 pb-1">
                    <svg class="w-8 h-8 mb-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="text-base text-white"><span class="font-semibold">Click to upload</span>
                        or drag and drop</p>
                    <p class="text-base text-white">Only CSV file</p>
                </div>
                <input id="dropzone-file" type="file" class="hidden" @input="readCSV" />
            </label>
        </div>
        <div class="text-end mt-1">
            <button class="px-2 py-1 rounded text-base bg-blue-600 text-white hover:bg-blue-800" @click="uploadReport"
                v-show="file">
                <div class="flex flex-row items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-if="loading">
                        <path fill="currentColor"
                            d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                            opacity=".5" />
                        <path fill="currentColor" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                            <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite"
                                to="360 12 12" type="rotate" />
                        </path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-else>
                        <path fill="currentColor"
                            d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-5 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" />
                    </svg>
                    Upload
                </div>
            </button>

        </div>
        <div class="mt-20">
            <b-card no-body class="border">
                <b-card-header class="p-1">
                    <b-card-title>Report List</b-card-title>
                </b-card-header>
                <b-table :items="items" :busy="busy" :fields="fields" responsive hover selectable select-mode="single">
                    <template #cell(createdAt.$date)="data">
                        {{ formatDate(data.value) }}
                    </template>
                    <template #table-busy>
                        <div class="text-center text-success my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>
                </b-table>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard, BCardHeader, BCardTitle, BTable, BSpinner, BRow, BCol, BFormGroup, BFormInput
} from 'bootstrap-vue'
import UserService from '@/services/UserService'
export default {
    components: { BCard, BCardHeader, BCardTitle, BTable, BSpinner, BRow, BCol, BFormGroup, BFormInput },
    data() {
        return {
            file: null,
            items: [],
            busy: true,
            loading: false,
            fields: [{ label: "File Name", key: "fileName" },
            { label: "Total Fields", key: "total" },
            { label: 'Uploaded At', key: 'createdAt.$date' }],
            title: null

        }
    },
    mounted() {
        this.getReports()
    },
    methods: {
        getReports: async function () {
            this.busy = false
            await UserService.getReports(JSON.parse(localStorage.getItem("auth")).discordId).then((res) => {
                this.items = res
            }).catch((error) => {
                console.log(error);
            })
        },
        uploadReport: async function () {
            this.loading = true
            let data = {
                fileName: this.title,
                user: JSON.parse(localStorage.getItem("auth")).discordId,
                filecsv: this.file
            }
            await UserService.uploadReport(data).then(() => {
                this.loading = false
                this.$swal({
                    title: "",
                    text: "File Uploaded Succesfully",
                    icon: "success",
                    allowOutsideClick: false,
                    showClass: {
                        popup: "animate__animated animate__fadeIn",
                    },
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.$router.go(0)
                    }
                });


            }).catch((error) => {
                this.loading = false
                console.log(error);
            })
        },
        readCSV: function (event) {
            const vm = this;
            const input = event.target.files[0];
            if (input) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    vm.file = reader.result;
                    var arr = vm.csvToArray(vm.file)
                    vm.file = arr
                };
                reader.readAsText(input);
            }
        },

        csvToArray: function (csv) {
            var lines = csv.split("\n");
            var result = [];
            var headers = lines[0].split(",");

            for (var i = 1; i < lines.length; i++) {
                var obj = {};
                var currentline = lines[i].split(",");

                for (var j = 0; j < headers.length; j++) {
                    obj[headers[j]] = currentline[j];
                }

                result.push(obj);
            }

            return result;
        },

        formatDate: function (date) {
            const newD = new Date(date);
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            const dateFormat = newD.toLocaleDateString('es-ES', options);
            return dateFormat
        }

    }
}
</script>